<template>
  <vx-card :title="title">
    <div class="vx-row mb-6" style="width:70%">
      <div class="vx-col sm:w-1/4 w-full flex items-center">
        <span>Territory</span>
      </div>
      <div class="vx-col sm:w-3/4 w-full">
        <multiselect
          v-model="territory.selected"
          :options="territory.options"
          placeholder="Type to search"
          track-by="name"
          label="name"
          :max-height="125"
        >
          <span slot="noResult">Oops! No data found</span>
          <template slot="singleLabel" slot-scope="props">
            <span class="option__desc">
              <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="props">
            <div class="option__desc">
              <span class="option__title">{{ props.option.code }} {{ props.option.name }}</span>
            </div>
          </template>
        </multiselect>
      </div>
    </div>
    <div class="vx-row mb-12">
      <div class="core vx-col md:w-1/1 w-full mb-base">
        <vs-table
          search
          description
          :sst="true"
          :data="table.data"
          :max-items="table.length"
          :total="table.total"
          @search="handleSearch"
          @change-page="handleChangePage"
          @sort="handleSort"
        >
          <template slot="header">
            <vs-dropdown vs-trigger-click class="cursor-pointer">
              <div
                class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
              >
                <span class="mr-2">{{this.table.start}} - {{this.table.end}} of {{this.table.total}}</span>
                <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
              </div>
              <vs-dropdown-menu>
                <vs-dropdown-item
                  v-for="item in table.limits"
                  :key="item"
                  @click="handleChangelength(item)"
                >
                  <span>{{item}}</span>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
            <div class="btn-group ml-5">
              <vs-button
                size="small"
                color="success"
                type="border"
                @click="handleExport('excel')"
              >Excel</vs-button>
              <vs-button size="small" color="primary" type="border" @click="handleExport('pdf')">PDF</vs-button>
            </div>
          </template>
          <template slot="thead">
            <vs-th sort-key="sku_code">Sku Code</vs-th>
            <vs-th sort-key="item_name">Item Name</vs-th>
            <vs-th sort-key="warehouse_code">Warehouse</vs-th>
            <vs-th sort-key="serial_number">SN/Batch</vs-th>
            <vs-th sort-key="expired_date">Exp. Date</vs-th>
            <vs-th sort-key="unit">Unit</vs-th>
            <vs-th sort-key="amount">Amount</vs-th>
          </template>

          <template v-for="(item, index) in table.data">
            <vs-tr :key="index">
              <vs-td>{{ item.sku_code }}</vs-td>
              <vs-td>{{ item.item_name }}</vs-td>
              <vs-td>({{ item.warehouse_code }}) {{ item.warehouse_name}}</vs-td>
              <vs-td>{{ item.serial_number }}/{{item.batch }}</vs-td>
              <vs-td>{{ item.expired_date }}</vs-td>
              <vs-td>{{ item.unit }} ({{ item.amount_uom }} pcs)</vs-td>
              <vs-td>{{ item.amount }}</vs-td>
            </vs-tr>
          </template>
        </vs-table>
        <vs-pagination style="padding-top:5px" :total="table.totalPage" v-model="setPage" />
      </div>
    </div>
  </vx-card>
</template>
<script>
export default {
  data() {
    return {
      title: "Stock Territory",
      baseUrl: "/api/v1/report/stock-view/territory",
      table: this.tableState(),
      territory: {
        selected: null,
        options: []
      },
      territoryCode: ""
    };
  },
  methods: {
    tableState() {
      return {
        data: [],
        length: 10,
        page: 1,
        search: "",
        order: "",
        sort: "",
        total: 0,
        totalPage: 0,
        totalSearch: 0,
        limits: [10, 25, 50, 100, "All"],
        start: 1,
        end: 0
      };
    },
    handleSearch(searching) {
      this.table.search = searching;
      this.table.page = 1;
      this.getData();
    },
    handleChangePage(page) {
      this.table.page = page;
      this.getData();
    },
    handleSort(key, active) {
      if (active) {
        this.table.order = key;
        this.table.sort = active;
      } else {
        this.table.order = "";
        this.table.sort = "";
      }
      this.getData();
    },
    handleChangelength(val) {
      this.table.length = val == "All" ? this.table.total : val;
      this.table.page = 1;
      this.getData();
    },
    handleClose() {
      this.getData();
      this.detail = false;
      this.action = "";
    },
    handleCreate() {
      this.id = null;
      this.detail = true;
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get(this.baseUrl, {
          params: {
            length: this.table.length,
            page: this.table.page,
            search: this.table.search,
            order: this.table.order,
            sort: this.table.sort,
            territory_code: this.territoryCode
          }
        })
        .then(resp => {
          if (resp.status == "success") {
            this.table.total = resp.data.total_record;
            this.table.totalPage = resp.data.total_page;
            this.table.totalSearch = resp.data.total_record_search;
            this.table.length = resp.data.total_record_per_page;
            this.table.data = resp.data.records;
            this.setStartEnd();
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
    setStartEnd() {
      let valStart =
        this.table.length * this.table.page - this.table.length + 1;
      if (valStart > this.table.total) {
        valStart = 1;
      }
      if (this.table.total == 0) {
        valStart = 0;
      }
      let valEnd = this.table.length * this.table.page;
      if (valEnd > this.table.total) {
        valEnd = this.table.total;
      }
      if (this.totalSearch < this.total) {
        valEnd = this.table.totalSearch;
      }

      this.table.start = valStart;
      this.table.end = valEnd;
    },
    getAllDataTerritoryArea() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/territory", {
          params: {
            order: "code",
            sort: "asc"
          }
        })
        .then(resp => {
          if (resp.status == "success") {
            this.territory.options = resp.data.records;
            if (this.territory.options.length > 0) {
              this.territory.selected = this.territory.options[0];
            }
            this.$vs.loading.close();
          } else {
            this.$vs.loading.close();
            console.log(resp.data);
          }
        });
    },
    handleExport(file) {
      // this.$vs.loading();
      this.$http
        .get("/api/v1/report/export", {
          params: {
            territory_code: this.territoryCode,
            option: "Stock Territory",
            file: file
          },
          responseType: "arraybuffer",
          headers: {
            Accept: "application/octet-stream"
          }
        })
        .then(resp => {
          // this.$vs.loading.close();
          if (resp.status == "error") {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
          var fileURL = window.URL.createObjectURL(new Blob([resp]));
          var fileLink = document.createElement("a");
          fileLink.href = fileURL;
          if (file == "excel") {
            fileLink.setAttribute(
              "download",
              this.territoryCode + "_stock-territory.xlsx"
            );
          } else {
            fileLink.setAttribute(
              "download",
              this.territoryCode + "_stock-territory.pdf"
            );
          }
          document.body.appendChild(fileLink);
          fileLink.click();
        });
    }
  },
  mounted() {
    this.getAllDataTerritoryArea();
  },
  watch: {
    "territory.selected": function(v) {
      if (v) {
        this.territoryCode = v.code;
        this.getData();
      } else {
        this.table = this.tableState();
      }
    }
  },
  computed: {
    setPage: {
      get() {
        return 1;
      },
      set(val) {
        if (val != this.table.page) {
          this.handleChangePage(val);
        }
      }
    }
  }
};
</script>